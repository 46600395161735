/*
المان غیر فعال شده
*/
[data-mds-dtp-guid][disabled] {
  opacity: 0.5;
  cursor: default;
}

/*
کانتینر بادی تقویم
*/
.mds-bs-dtp-container {
  min-width: 300px;
  font-size: 12px;
  position: relative;
  overflow: visible;
}

/*
عنوان تقویم در حالت in line
*/
.mds-bs-dtp-container [mds-dtp-inline-header] {
  border-bottom: solid 1px #ccc !important;
}

.mds-bs-dtp-container.rtl {
  direction: rtl;
}

.mds-bs-dtp-container .select-year-box,
.mds-bs-dtp-container .select-year-inline-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  text-align: center;
  transition: all 200ms ease;
  overflow: auto;
  z-index: 9999999;
}

.mds-bs-dtp-container .select-year-inline-box {
  height: 90%;
}

/*
اسکرول بار ها
*/
.select-year-box::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #e4e4e4;
}

.select-year-box::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/*
  برای نمایش لیست سال های قابل انتخاب در مد inline
*/
.mds-bs-dtp-container .select-year-box.inline {
  top: 27px !important;
  overflow: auto;
}

.mds-bs-dtp-container .select-year-box.w-0,
.mds-bs-dtp-container .select-year-inline-box.w-0 {
  height: 0;
}

.mds-bs-dtp-container .select-year-box button {
  font-size: 16px;
}

.mds-bs-dtp-container .select-year-box [data-selected-year] button {
  background-color: aquamarine;
}

.mds-bs-dtp-container .select-year-box [data-current-year] button {
  background-color: #c7f7c7;
}

.mds-bs-dtp-container.rtl table:not([dir="ltr"]) {
  direction: rtl;
}

.mds-bs-dtp-container.rtl tfoot table {
  direction: ltr;
}

.mds-bs-dtp-container.rtl .dropdown-toggle::after {
  margin-left: 0;
  margin-right: 0.255em;
}

.mds-bs-dtp-container * {
  font-size: 12px;
}

.mds-bs-dtp-container table {
  margin-bottom: 0;
}

.mds-bs-dtp-container table th,
.mds-bs-dtp-container table td {
  padding: 1px;
  font-size: 14px;
  text-align: center;
}

.mds-bs-dtp-container table tbody td[data-next-month] .btn,
.mds-bs-dtp-container table tbody td[data-prev-month] .btn {
  background: #fff;
  border: none;
  cursor: default;
}

.mds-bs-dtp-container table table {
  background: transparent !important;
  background-color: transparent !important;
  border: none;
}

.mds-bs-dtp-container table table th {
  border: none;
}

.mds-bs-dtp-container table table input[type="text"] {
  width: 50px;
  border: none;
  text-align: center;
  background-color: #fff;
}

.mds-bs-dtp-container th {
  padding: 0;
}

.mds-bs-dtp-container table table tbody.days {
  border: 0;
}

.mds-bs-dtp-container table table tbody.days tr:first-child>td {
  vertical-align: top;
  padding-bottom: 5px;
  width: 14.2%;
  cursor: default;
  font-weight: bold;
}

.mds-bs-dtp-container table table tbody.days td[data-day] {
  cursor: pointer;
  transition: all 200ms ease;
}

.mds-bs-dtp-container table table tbody.days td[data-day][data-today] {
  background-color: aquamarine;
}

.mds-bs-dtp-container table table tbody.days td[data-day][data-mds-dtp-selected-day] {
  background-color: #96daff;
}

.mds-bs-dtp-container table table tbody.days td.selected-range-days-start-end {
  background-color: #c9e1ff !important;
}

/*
روزهایی که در رنج انتخابی دیت پیکر هستند
*/
.mds-bs-dtp-container table table tbody.days td.selected-range-days {
  background-color: #d9f2e6;
}

/*
روزهایی که مربوط به ماه های قبل و بعد هستند و در رنج انتخابی دیت پیکر هستند
*/
.mds-bs-dtp-container table table tbody.days td.selected-range-days-nm {
  background-color: #78ffbe;
}

.mds-bs-dtp-container table table tbody.days td[data-special-date] {
  background-color: #c7f7c7;
  border: 1px solid #00ce00;
  transition: all 200ms ease;
}

.mds-bs-dtp-container table table tbody.days td[data-special-date]:hover {
  background-color: #7bff7b;
  border-color: #31cc31;
}

.mds-bs-dtp-container table table tbody.days td[data-pm],
.mds-bs-dtp-container table table tbody.days td[data-nm],
.mds-bs-dtp-container table table tbody.days td[disabled] {
  cursor: default;
  opacity: 0.2;
  transition: none;
}

.mds-bs-dtp-container table table tbody.days td[data-pm]:hover,
.mds-bs-dtp-container table table tbody.days td[data-nm]:hover,
.mds-bs-dtp-container table table tbody.days td[disabled]:hover {
  cursor: default;
  opacity: 0.3;
  transition: none;
}

.mds-bs-dtp-container table table tbody.days td[data-day]:not([data-special-date]):hover,
.mds-bs-dtp-container table table tbody.days td[data-nm]:hover,
.mds-bs-dtp-container table table tbody.days td[data-pm]:hover {
  background: #ccc;
}

.mds-bs-dtp-container input[data-mds-dtp-time][type="time"] {
  text-align: center;
  border: none;
  font-size: 18px;
}

.mds-bs-dtp-container input[data-mds-dtp-time][type="time"]:focus {
  outline: none;
}

.mds-bs-dtp-container .btn {
  width: 100%;
  margin-bottom: 1px;
  padding: 1px;
  font-size: 14px;
}

.mds-bs-dtp-container .btn[disabled] {
  opacity: 0.2;
}

.mds-bs-dtp-container .dropdown {
  padding: 0;
  padding-bottom: 1px;
  text-align: center;
}

.mds-bs-dtp-container .dropdown-menu {
  min-width: 100px;
  width: 100px;
}

.mds-bs-dtp-container .dropdown-item {
  padding: 0;
  padding-bottom: 1px;
  cursor: pointer;
  text-align: center;
}

.mds-bs-dtp-container .dropdown-item:not(.disabled):hover {
  background-color: #ccc;
}

.mds-bs-dtp-container .dropdown-item.disabled {
  opacity: 0.2;
  cursor: default;
}

.mds-bs-dtp-container .dropdown-divider {
  margin-bottom: 1px;
  cursor: default;
}

.mds-bs-persian-datetime-picker-popover {
  max-width: 100%;
  -webkit-box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.1);
  z-index: 999999999;
}

.mds-bs-persian-datetime-picker-modal * {
  font-size: 24px !important;
}

/*
عنوان مدال نمایش تقویم
*/
[data-mds-dtp] .modal-title{
  line-height: 0.5;
  width: 100%;
  text-align: center;
}